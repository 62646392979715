.ant-pro-basicLayout {
  --pro-layout-header-height: 48px;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  & .ant-layout-header {
    & .ant-pro-fixed-header {
      position: fixed;
      top: 0;
    }
  }

  & .ant-pro-basicLayout-content {
    position: relative;
    margin: 24px;

    & .ant-pro-page-container {
      margin: -24px -24px 0;
    }

    & .ant-pro-basicLayout-disable-margin {
      margin: 0;

      & .ant-pro-page-container {
        margin: 0;
      }
    }
    & > .ant-layout {
      max-height: 100%;

      & .ant-layout-header {
        background-color: none !important;
      }
    }
  }

  & .ant-pro-basicLayout-is-children.ant-pro-basicLayout-fix-siderbar {
    height: 100vh;
    overflow: hidden;
    transform: rotate(0);
  }

  & .ant-pro-basicLayout-has-header {
    & .tech-page-container {
      height: calc(100vh - var(--pro-layout-header-height));
    }
    & .ant-pro-basicLayout-is-children.ant-pro-basicLayout-has-header {
      & .tech-page-container {
        height: calc(100vh - var(--pro-layout-header-height) - var(--pro-layout-header-height));
      }
      & .ant-pro-basicLayout-is-children {
        min-height: calc(100vh - var(--pro-layout-header-height));
        & .ant-pro-basicLayout-fix-siderbar {
          height: calc(100vh - var(--pro-layout-header-height));
        }
      }
    }
  }
}

.ant-pro-sider {
  position: relative;
  background-color: var(--layout-sider-background);
  border-right: 0;
  transition: var(--background-color) 0.3s;

  & .ant-menu-vertical .ant-menu-item:not(:last-child),
  & .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  & .ant-menu-vertical-right .ant-menu-item:not(:last-child),
  & .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 4px;
  }

  & .ant-menu-inline-collapsed .ant-menu-submenu-title {
    width: 48px;
    padding: 0 16px !important;
  }

  & .ant-layout-sider-light {
    & .ant-menu-item a {
      color: var(--heading-color);
    }
    & .ant-menu-item-selected a,
    & .ant-menu-item a:hover {
      color: var(--primary-color);
    }
  }

  & .ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
  & .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
  &
    .ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-submenu
    > .ant-menu-submenu-title
    .anticon
    + span,
  & .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
    display: none;
  }

  & ul.ant-menu-sub {
    & li.ant-menu-item .ant-pro-menu-item {
      padding-left: 8px;
    }
  }

  & .ant-pro-sider-logo {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 8px 8px 0;
    line-height: 32px;
    cursor: pointer;

    & > a {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 32px;
    }

    & img {
      display: inline-block;
      height: 32px;
      vertical-align: middle;
      transition: height 0.2s;
      /* COLOR: BLACK with white logo */
      filter: invert(100%);
      -webkit-filter: invert(100%);
    }
    & h1 {
      display: inline-block;
      height: 32px;
      margin: 0 0 0 12px;
      color: black;
      font-weight: 600;
      font-size: 18px;
      line-height: 32px;
      vertical-align: middle;
      animation: fade-in;
      animation-duration: 0.2s;
    }
  }

  & .ant-pro-sider-extra {
    margin-bottom: 16px;
    padding: 0 16px;
    & .ant-pro-sider-no-logo {
      margin-top: 16px;
    }
  }

  & .ant-pro-sider-menu {
    position: relative;
    z-index: 10;
    min-height: 100%;
    box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  }

  & .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  & .ant-layout-sider-collapsed {
    & .ant-menu-inline-collapsed {
      width: 48px;
    }
    & .ant-pro-sider {
      & .ant-pro-sider-logo {
        padding: 16px 8px;
      }
    }
  }

  & .ant-menu-inline-collapsed {
    & > .ant-menu-item .sider-menu-item-img + span,
    & > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .sider-menu-item-img + span,
    & > .ant-menu-submenu > .ant-menu-submenu-title .sider-menu-item-img + span {
      display: inline-block;
      max-width: 0;
      opacity: 0;
    }

    & .ant-menu-item {
      width: 48px;
      padding: 0 16px !important;
    }

    & .ant-pro-menu-item-title {
      display: none;
    }

    & .ant-menu-submenu {
      & .ant-pro-sider-title {
        width: 48px;
        padding: 0 16px !important;
      }
    }
  }

  & .ant-pro-sider-fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
    & > .ant-menu-root {
      & :not(.ant-pro-sider-link-menu) {
        height: calc(100vh - 60);
        overflow-y: auto;
      }
    }
  }

  & .ant-menu-inline {
    & .ant-menu-item,
    & .ant-menu-submenu-title {
      width: 100%;
    }
  }

  & .ant-pro-sider-light {
    background-color: '#FFF';
    box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
    & .ant-pro-sider-logo {
      & h1 {
        color: var(--primary-color);
      }
    }
    & .ant-menu-light {
      border-right-color: transparent;
    }

    & .ant-pro-sider-collapsed-button {
      border-top: solid 1px rgba(29, 35, 41, 0.05);
    }
  }

  & .ant-pro-sider-icon {
    width: 14px;
    vertical-align: baseline;
  }

  & .ant-pro-sider-links {
    width: 100%;
    & ul.ant-menu-root {
      height: auto;
    }
  }

  & .ant-pro-sider-collapsed-button {
    border-top: solid 1px rgba(0, 0, 0, 0.25);
    & .anticon {
      font-size: 16px;
    }
  }

  & .top-nav-menu li.ant-menu-item {
    height: 100%;
    line-height: 1;
  }
  & .drawer .drawer-content {
    background: var(--layout-sider-background);
  }

  & .ant-menu-item .sider-menu-item-img + span,
  & .ant-menu-submenu-title .sider-menu-item-img + span {
    opacity: 1;
    transition: opacity 0.3s @ease-in-out, width 0.3s @ease-in-out;
  }
}

@keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }
  99% {
    display: none;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
