/* @import '~antd/dist/antd.css'; */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html,
body,
#root {
  height: 100%;
}

.colorWeak {
  filter: invert(80%);
}

.ant-layout {
  min-height: 100vh;
}

canvas {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
ol {
  list-style: none;
}

@media (max-width: 320) {
  .ant-table {
    width: 100%;
    overflow-x: auto;

    & .ant-table-thead>tr,
    & .ant-table-tbody>tr {

      &>th,
      &>td {
        white-space: pre;

        &>span {
          display: block;
        }
      }
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Common styles */

:root {
  --param-size: 1em;
  --param-color: transparent;
  --param-position: center;
  --param-percent: 100%;
}

.resetMargin0 {
  margin: 0 !important;
}

.resetPadding0 {
  padding: 0 !important;
}

/* Common styles */

/* Custom styles */
.formControl>* {
  margin-bottom: 1em;
}

.formControl>*:last-child {
  margin-bottom: 0;
}

.formControl>.flex-row {
  align-items: center;
}

.formControl>.flex-row>* {
  margin-right: 1em;
}

.formControl>.flex-row>*:last-child {
  margin-right: 0;
}

.fixBreakWords {
  -ms-word-break: break-word;
  word-break: break-word;
  line-break: strict;
}

.fixBreakWordsJapan {
  -ms-word-break: keep-all;
  word-break: keep-all;
  line-break: strict;
}

/* Custom styles */

.ant-carousel .slick-dots li button {
  background-color: gray;
}

.ant-carousel .slick-dots li.slick-active button {
  background-color: blue !important;
}

.ant-menu-item {
  display: flex;
  align-items: center;
}

.ant-pro-menu-item-title {
  white-space: normal;
  height: auto;
}