.rowSwiper .swiper {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.rowSwiper .swiper > * {
  margin: 0 3px;
}

.rowSwiper {
  display: flex;
  margin: 0;
  position: relative;
  flex-direction: row;
  justify-content: center;
  overflow-x: auto;
  overscroll-behavior-x: initial;
}
